.box-shadow{
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
    transition: all 0.3s ease-out;

} 

.box-shadow:hover{
    backface-visibility: hidden;
    transform: scale(1.05,1.05);
    opacity: 1;

}
.card-img-top{
  height: 200px;
}
.card-title{
   text-align: center;
   font-weight: 700;
    color: #2D4988;
    font-family: "Open Sans", sans-serif;
    font-size: 23px;
}
.card-title h4{
  font-weight: 500;
}
.obesity img{
    transition: all 1.1s ease;
}
.obesity:hover img{
    transform: scale(1.3);
}
.obesity{
overflow: hidden;

}