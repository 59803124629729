/* heading*/
.section_heading {
    text-align: center;
    width: 100%;
    padding-bottom: 10px;
}
h1 {
    font-size: 32px !important;
    font-weight: 900 !important;
    margin: 0.67em 0;
}
.section_heading h1 span, .section_heading h2 span {
     color: var(--bg-color-e74);
}
.separator {
    margin: 5px auto 0px;
    width: 120px;
    position: relative;
}
.separator ul {
    padding: 0px;
    margin: 0px;
}
ul, li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
ul, li {
    padding: 0px;
    margin: 0px;
}

.separator ul::before {
    content: '';
    width: 40px;
    height: 1px;
    background: #c9c9c9;
    left: 0;
    top: 61%;
    position: absolute;
}
.separator ul li {
    display: inline-block;
    width: 3px;
    height: 7px;
    margin: 0px 2px;
    padding: 0px;
}
.separator ul li {
    background: #2E8B57
}



.separator ul::after {
    content: '';
    width: 40px;
    height: 1px;
    background: #c9c9c9;
    right: 0;
    top: 61%;
    position: absolute;
}

/* About Hospital One */

.single-feature {
    position: relative;
    padding: 30px;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    box-shadow: 0 0 50px rgb(153 153 153 / 20%);
}

.single-feature .feature-head {
    margin-bottom: 30px;
    
}
.single-feature .feature_head {
    position: absolute;
}
.single-feature .feature-head h4 {
    padding: 11px 0 0 60px;
    font-size: 18px;
    font-weight: 700;
    color: var(--bg-color-e74) !important;
}
.single-feature .feature-btn .f-btn {
    font-weight: 500;
    color: #222;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}
.f-btn {
    text-decoration: none;
    transition: all .3s ease-in-out;
    text-decoration: none;
    background-color: transparent;
}
.f-btn:hover{
    color: #007bff !important;
}
.single-feature:hover {
    cursor: pointer;
    box-shadow: 0 0 50px rgba(221, 218, 218, 0.2);
}