/* cards*/
.womenchild .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  border: 1px solid green;
 box-shadow: 0px 5px 5px -4px rgb(0 0 0 / 25%);
  border-radius: 8px;
   transition: all 0.3s ease-out;
  z-index: 1;
}
.womenchild .icon-box:hover {
   box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
     backface-visibility: hidden;
    transform: scale(1.10,1.10);
    opacity: 1;
}

.womenchild .icon-box::before {
  content: '';
  position: absolute;
  background: #cbe0fb;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.womenchild .icon-box:hover::before {
   background:#2D4988;
  top: 0;
  border-radius: 0px;

}

.womenchild .icon {
  margin-bottom: 15px;
}

.womenchild .icon i {
  font-size: 48px;
  line-height: 1;
  color: #106eea;
  transition: all 0.3s ease-in-out;
}

.womenchild .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.womenchild .title a {
  color: #111;
  text-decoration: none;
}

.womenchild .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.womenchild .icon-box:hover .title a, .womenchild .icon-box:hover .description {
  color: #fff;
}

.womenchild .icon-box:hover .icon i {
  color: #fff;
}

/* Women3*/


.beforechildbirth {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}
.beforechildbirth .image-wrapper {
  display: block;
  width: 50%;
}
.beforechildbirth .image-wrapper img {
  width: 100%;
}
@media screen and (max-width: 767.98px) {
  .beforechildbirth .image-wrapper {
    width: 100%;
  }
}
.beforechildbirth .content-women {
  display: block;
  width: 50%;
}
@media screen and (max-width: 767.98px) {
  .beforechildbirth .content-women {
    width: 100%;
  }
}
.beforechildbirth .content-women .wrapper {
  display: block;
  margin: 1.25rem 0;
  padding: 0 2.5rem;
  position: relative;
}
.beforechildbirth .content-women .wrapper ol li {
  display: inline-block;
  margin: 1.25rem 0;
  position: relative;
  display: block;
  padding: 0.3125rem 0;
}
.beforechildbirth .content-women .wrapper ol li .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.3125rem 0;
}
.beforechildbirth .content-women .wrapper ol li .name span {
  font-size: 1.125rem;
  color: #0a130e;
}
.beforechildbirth .content-women .wrapper ol li .name h6 {
  display: inline-block;
  font-size: 1.125rem;
  color: #0a0a13;
  margin: 0.3125rem 0;
  text-transform: capitalize;
}
.beforechildbirth .content-women .wrapper ol li .progress {
  height: 0.625rem;
  border-radius: 1.25rem;
}
.beforechildbirth .content-women .wrapper ol li .progress .progress-bar {
  background: #0a8f32;
}
.beforechildbirth .content-women .inner {
  padding: 0.625rem 0;
  position: relative;
}
.beforechildbirth .content-women .inner span {
  display: block;
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  color: var(--bg-color-e74);
  padding-left: 0.9375rem;
  margin: 0.625rem 0;
}
.beforechildbirth .content-women .inner span::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 0.1875rem;
  background: #199137;
}
.beforechildbirth .content-women .inner h2 {
  font-size: 1.185rem;
  color: #0a0a13;
  display: block;
  margin: 0.625rem 0;
  position: relative;
  text-transform: capitalize;
}
.beforechildbirth .content-women .inner p {
  font-size: 0.9375rem;
  color: #666666;
  display: block;
  margin: 0.625rem 0;
}
.beforechildbirth .content-women .inner ol {
  width: 100%;
  flex-wrap: wrap;
}
.beforechildbirth .content-women .inner ol li {
  width: 33.3%;
  padding: 0.9375rem 0.625rem;
  position: relative;
  display: flex;
  margin: 0.625rem 0;
  transition: all ease 0.5s;
}
@media screen and (max-width: 1044px) {
  .beforechildbirth .content-women .inner ol li {
    width: 100% !important;
    margin: 0px;
    padding: 5px;
  }
}
.beforechildbirth .content-women .inner ol li:hover {
  background: rgb(0,36,9);
background: linear-gradient(90deg, rgba(0,36,9,1) 0%, rgba(76,168,59,1) 100%, rgba(0,212,255,1) 100%);
  border-radius: 0.3125rem;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.03), 0 1px 4px rgba(0, 0, 0, 0.04);
}
.beforechildbirth .content-women .inner ol li:hover div {
  color: #fff;
}
.beforechildbirth .content-women .inner ol li:hover h4 {
  color: #fff;
}
@media screen and (max-width: 575.98px) {
  .beforechildbirth .content-women .inner ol li {
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .beforechildbirth .content-women .inner ol li {
    width: 50%;
  }
}
.beforechildbirth .content-women .inner ol li div {
  font-size: 1.25rem;
  color: #30057b;
  margin-right: 0.625rem;
  font-weight: bold;
}
.beforechildbirth .content-women .inner ol li h4 {
  font-size: 1rem;
  color: #0a0a13;
  font-weight: 600;
  line-height: 1.5625rem;
  letter-spacing: 0.3px;
}