
   .diagnosis-banner1{
    background:#BCCCFE;
       background-size: cover;
        
   }
   .diagnosis-banner2{
    background:#7dc580;
       background-size: cover;
     
       
   }
   .diagnosis-banner3{
    background:#3DA7FE;
       background-size: cover;
        
   }
   
   .ds-cont{
   padding:110px 0px;
   }
   .ds-cont h2 {
       font-size: 33px;
       color: #FFF;
       padding-bottom: 30px;
   }
   .ds-cont p {
       font-size: 16px;
       color: #FFF;
       line-height: 30px;
   }
   .ds-cont1{
   padding:110px 0px;
   }
   .ds-cont1 h2 {
       font-size: 33px;
       color:black;
       padding-bottom: 30px;
   }
   .ds-cont1 p {
       font-size: 16px;
       color:black;
       line-height: 30px;
   }
   .challenges1{
      font-weight: 700;
       color: black;
     font-family: "Open Sans", sans-serif;
   }
   
   .challenges span{
   
    color: #fb5363;
   }
   
   .Diagnosisimg{
    max-width: 100%;
       height:450px;

   }
   
   @media only screen and (max-width: 767px){
    
     .ds-cont p {
       font-size: 17px !important;
       color: #FFF;
       line-height: 1.5;
   }
    
   }
   
   
   
  