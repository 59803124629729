.neuro-box {
	margin-bottom: 40px;
}

.neuro-icon {
	margin: 0 20px 20px 0;
	float: left;
}	

.neuro-desc h5 {
	margin-bottom: 10px;
}
.img-responsive{
    height: fit-content;
    width: 100%;
}