.about-container{
    position: relative;
    padding: 30px 120px 30px;
    text-align: justify;
    font-size: 16px;
}
.about-image{
    display: block;
    width: 40% !important;
}
.about-title{
    position: relative;
    padding: 30px 0px 30px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    /* text-align: center; */
}

/* About One */

.about-one-container-one{
    position: relative;
    margin-bottom: 50px;
    background-color: transparent;
    background-image: linear-gradient(250deg, #fe4c2e 0%, #f12184 94%);
    opacity: 0.81;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    padding: 60px 40px 60px 40px !important;
}
.about-one-container{
    position: relative;
    margin-bottom: 50px;
}
.about-one-card{
    position: relative;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    padding: 60px 40px 60px 40px !important;
    background-color: #ffffff !important;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #d8d8d8;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
    justify-content: cenrter !important;
}
.about-one-card-head {
    font-size: 18px;
    font-weight: 900;
    color: var(--bg-color-e74);
    margin-bottom: 30px !important;
}
.about-one-col{
    position: relative;
    text-align: center;
}

/* About Two */
.about-two-title{
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
}
.about-two-ul{
    list-style-type: none;
    padding-bottom: 20px;
}
.about-two-ul li{
    margin: 0px 0px 5px;
    position: relative;
    /* padding-left: 20px; */
    font-size: 16px;
    line-height: 1.63;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    color: #333333;
    text-align: justify;
}
.about-two-ul li:before{
    content: "\21E8" !important;
    font-family: FontAwesome;
    position: relative;
    left: 0px;
    top: 0;
    margin-right: 5px;
    font-size: 18px;
}

/* About Stores */

/* About Store */

.about-store-img{
    position: relative;
    width: 100%;
}
.about-store-title{
    position: relative;
    /* padding: 30px 0px 30px; */
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    /* text-align: center; */
}

.about-store-title-one{
    position: relative;
    /* padding: 30px 0px 30px; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color:var(--bg-color-e74);
    /* text-align: center; */
}
.about-store-text p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color:var(--text-black-color-000);
    text-align: justify;
}
.about-store-text-one{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color:var(--text-color-fff);
    text-align: justify;
}

/* Medical Team */

.medical-team-col p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color:var(--text-black-color-000);
    text-align: justify;
}
.medical-team-title{
    position: relative;
    /* padding: 30px 0px 30px; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: left !important;
}
.medical-team-text{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color:var(--text-black-color-000);
    text-align: justify;
}
.medical-team-des{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color:var(--text-black-color-000);
    text-align: justify;
}
.medical-team-link{
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 42px;
    padding: 0 30px;

    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 10px;
    outline: none;
    background-color:var(--text-gold-color-d4a);
}