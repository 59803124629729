.footer-container{
    position: relative;
    width: 100%;
    background-color: var(--text-color-0e3);
    color: white;
}
.footer-container-one{
    position: relative;
    padding: 30px 10px 30px;
}
.footer-quick-link{
    text-align: left;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    font-size: 25px;
}
.footer-link{
    text-align: left;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #ccc;
    list-style-type: none;
}

.footer-link-a a{
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #ccc;
    text-decoration: none;
}
.footer-icon-div a{
    position: relative;
    /* display: -webkit-inline-flex; */
    padding: 5px 5px 5px;
    text-decoration: none;
}

.footer-icon{
    width: 10%;
}
.footer-image{
  width: 100%;
  background-color: #fff;
}

@media (max-width: 500px) {
    .footer-container-one{
        position: relative;
        padding: 30px 30px 30px;
    }
    .footer-image{
        width: 100%;
        background-color: #fff;
      }
      .footer-quick-link{
        text-align: left;
        font-weight: 700;
        font-style: normal;
        color: #fff;
        font-size: 25px;
    }
    .footer-icon{
        width: 20%;
    }
    .footer-col-3{
        margin-left: -30px;
    }
}