.homeservice-image{
    position: relative;
    display: block;
    width: 50% !important;
    
}
.card-image{
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    background-color: 	#fff !important;
    border-radius: 10px !important;
    transition: all 0.3s ease-out;
    box-shadow: 0 0 40px rgba(207, 206, 206, 0.938);
    box-sizing: border-box;
}
.card-image:hover{
    cursor: pointer;
    border-color:  var(--text-gold-color-d4a);
    border-radius: 20px !important;
    box-shadow: 0 0 30px rgba(97, 107, 98, 0.938);
    transform: translateY(-5px);
    /*backface-visibility: hidden;
    transform: scale(0.9,0.9);*/
    opacity: 1;
    
}
.homeservice-title{
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color:var(--text-color-000);
    text-align: center;
}
.homeservice-link{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: var(--text-gold-color-d4a) !important;
    border-radius: 10px !important;
    outline: none;
    background-color: var(--text-gold-color-d4a) !important;
}

.home-services-main-title{
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: center;
}
.homeservice-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-color-000) !important;
}

/* Home Explore */

.home-explore-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40vh !important;
    margin-top:50px ;
    background-color: #ED5001;
    color: #fff;
}
.home-explore-title{
  font-size: 30px;
  font-weight: 600;
}
.home-explore-text{
  font-size: 14px;
}

  .home-contact-btn{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: var(--text-gold-color-d4a) !important;
    border-radius: 10px !important;
    outline: none;
    background-color: var(--text-gold-color-d4a) !important;
}
