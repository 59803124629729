.homeabout-main{
    position: relative;
    padding: 10px 0px 0px;
}

.homeabout-title{
    position: relative;
    padding: 30px 0px 30px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: center;
}
.homeabout-col p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color:var(--text-black-color-000);
    text-align: justify;
}
.homeabout-image{
    position: relative;
    display: block;
    width: 100%;
}
.about-link{
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 42px;
    padding: 0 20px;

    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 10px;
    outline: none;
    background-color:var(--text-gold-color-d4a);
   
}

@media (max-width: 600px){

    .homeabout-main{
        position: relative;
        padding: 0px 0px 0px;
    }
    .homeabout-title{
        position: relative;
        padding: 30px 0px 30px;
        font-style: normal;
        font-weight: 900;
        font-size: 25px;
        line-height: 30px;
        color:var(--bg-color-e74);
        text-align: center;
    }
    .homeabout-col p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color:var(--text-black-color-000);
        text-align: justify;
    }

    .homeabout-image{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 40px;
    }
   
}